<template>
<v-container>
    <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        grow
    >
        <v-tab>Общая стата</v-tab>
        <v-tab>Стата по размерам/цветам</v-tab>
        <v-tab>Артикулы</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item>
        <v-card>
        <v-card-title>
            Итоги
        </v-card-title>
        <v-card-text>
            <v-simple-table v-if="ordersTotals && ordersTotals.unique && salesTotals && salesTotals.month">
                <thead>
                <tr>
                    <th></th>
                    <th>Сегодня</th>
                    <th>Вчера</th>
                    <th>Неделя</th>
                    <th>2 недели</th>
                    <th>Месяц</th>
                    <th>Отказы/Возвраты</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Заказы</td>
                    <td>{{ordersTotals.total.today}} ({{ordersTotals.unique.today}})</td>
                    <td>{{ordersTotals.total.yesterday}} ({{ordersTotals.unique.yesterday}})</td>
                    <td>{{ordersTotals.total.week}} ({{ordersTotals.unique.week}})</td>
                    <td>{{ordersTotals.total.week2}} ({{ordersTotals.unique.week2}})</td>
                    <td>{{ordersTotals.total.month}} ({{ordersTotals.unique.month}})</td>
                    <td>{{ordersTotals.total.returns}}</td>
                </tr>
                <tr>
                    <td>Продажи</td>
                    <td>{{salesTotals.today}} ({{salesTotals.return_today}})</td>
                    <td>{{salesTotals.yesterday}}</td>
                    <td>{{salesTotals.week}}</td>
                    <td>{{salesTotals.week2}}</td>
                    <td>{{salesTotals.month}}</td>
                    <td>{{salesTotals.return}}</td>
                </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
    </v-card>

    <v-divider class="mb-10"></v-divider>
    <v-card>
    <v-card-title>
        Заказы
    </v-card-title>
    <v-card-text>
    <v-data-table hide-default-footer disable-pagination :items="orders" :headers="[
        {'text': 'Товар', 'value': 'product'},
        {'text': 'Сегодня', 'value': 'today'},
        {'text': 'Вчера', 'value': 'yesterday'},
        {'text': 'Неделя', 'value': 'week'},
        {'text': '2 недели', 'value': 'week2'},
        {'text': 'Месяц', 'value': 'month'},
        {'text': 'Всего', 'value': 'total'},
        {'text': 'Отказы', 'value': 'returns'},
    ]">

    </v-data-table>
    </v-card-text>
    </v-card>
    <v-divider class="mb-10"></v-divider>
    <v-card>
        <v-card-title>
            Продажи
        </v-card-title>
        <v-card-text>
    <v-data-table hide-default-footer disable-pagination :items="sales" :headers="[
        {'text': 'Товар', 'value': 'product'},
        {'text': 'Сегодня', 'value': 'today'},
        {'text': 'Вчера', 'value': 'yesterday'},
        {'text': 'Неделя', 'value': 'week'},
        {'text': '2 недели', 'value': 'week2'},
        {'text': 'Месяц', 'value': 'month'},
        {'text': 'Всего', 'value': 'total'},
        {'text': 'Возвраты (сегодня)', 'value': 'return_today'},
        {'text': 'Возвраты', 'value': 'return'},
    ]">

    </v-data-table>
    </v-card-text>
    </v-card>
        </v-tab-item>
        <v-tab-item>
            <v-card>
                <v-card-text>
                    <template v-for="(item, productId) in skuStat.products">
                        <v-card  :key="'prod'+ productId" v-if="skuStat.productSizes[productId]" class="mt-5">
                            <v-card-text>
                            <v-simple-table dense >
                                <thead>
                                <tr>
                                <th class="title" width="250">{{item.title}}</th>
                                    <template v-for="(size,sizeId) in skuStat.sizes">
                                        <th :key="'size' + productId + '.'+ sizeId" v-if="skuStat.productSizes[productId][sizeId]"><span class="justify-center d-flex">{{size.title}}</span></th>
                                    </template>
                                </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(color,colorId) in skuStat.colors">
                                        <tr v-if="skuStat.productColors[productId][colorId]" :key="'color' + productId + '.'+ colorId">
                                            <td>{{color.title}}</td>
                                            <template v-for="(size,sizeId) in skuStat.sizes">
                                                <td  :style="skuClass(productId, colorId, sizeId)" :key="'size' + productId +'.' + colorId+ '.'+ sizeId" v-if="skuStat.productSizes[productId][sizeId]">
                                                        <v-tooltip bottom>
                                                          <template v-slot:activator="{ on, attrs }">
                                                            <span
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                class="justify-center d-flex"
                                                            >
                                                               <template v-if="skuStat.stock[productId] && skuStat.stock[productId][colorId] && skuStat.stock[productId][colorId][sizeId]">{{skuStat.stock[productId][colorId][sizeId].q}}</template>
                                                                <template v-else>0</template>
                                                            </span>
                                                          </template>
                                                              <v-simple-table>
                                                                <tbody>
                                                                    <tr  v-if="skuStat.orders[productId] && skuStat.orders[productId][colorId] && skuStat.orders[productId][colorId][sizeId]">
                                                                        <td>Заказы</td>
                                                                        <td>{{skuStat.orders[productId][colorId][sizeId].q}}</td>
                                                                        <td>-{{skuStat.orders[productId][colorId][sizeId].returns}}</td>
                                                                    </tr>
                                                                    <tr  v-if="skuStat.sales[productId] && skuStat.sales[productId][colorId] && skuStat.sales[productId][colorId][sizeId]">
                                                                        <td>Продано</td>
                                                                        <td>{{skuStat.sales[productId][colorId][sizeId].q}}</td>
                                                                        <td>-{{skuStat.sales[productId][colorId][sizeId].returns}}</td>
                                                                    </tr>
                                                                </tbody>
                                                              </v-simple-table>
                                                        </v-tooltip>
                                                </td>
                                            </template>
                                        </tr>
                                    </template>
                                </tbody>
                            </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-card-text>
            </v-card>
        </v-tab-item>
<v-tab-item>
    <v-textarea v-model="arts"></v-textarea>
</v-tab-item>
    </v-tabs-items>
    <v-overlay :value="overlay" style="z-index:1100">
        <div>
            <v-alert v-if="!errorMessage" type="success" >
                Успешно
            </v-alert>
            <v-alert v-else type="error" >
                {{errorMessage}}
            </v-alert>
        </div>
        <div @click="overlay = false" style="position: fixed; top:0; bottom: 0; left: 0; right:0; background-color: rgba(255,255,255,0); opacity: 0.1"></div>
    </v-overlay>

    <v-overlay :value="progress" style="z-index:1000">
        <div class="text-center">
            <v-progress-circular
                indeterminate
                color="primary"
                style="z-index:1001"
            ></v-progress-circular>
        </div>
    </v-overlay>
</v-container>
</template>

<style scoped>
    .v-data-table
    tbody
    tr:hover:not(.v-data-table__expanded__content) {
        background: #ffffff !important;
    }
</style>


<script>
    // import axios from "axios";

    import axios from "axios";

    export default {
        data: () => ({
            tab: null,
            overlay:false,
            progress: false,
            errorMessage: '',
            orders: [],
            sales: [],
            ordersTotals: [],
            salesTotals: [],
            skuStat: {},
            arts: ''
        }),
        methods: {
            refresh() {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);

                let $this = this;
                this.progress = true;
                axios({
                    url: '/api/wb/stat',
                    method: 'GET',
                    params: {
                        from: urlParams.get('from'),
                        to: urlParams.get('to')
                    }
                }).then(function (response) {
                    if (response.data.orders) {
                        $this.orders = response.data.orders;
                        $this.sales = response.data.sales;
                        $this.ordersTotals = response.data.orders_totals;
                        $this.salesTotals = response.data.sales_totals;
                        $this.progress = false;
                    } else {
                        $this.errorMessage = response.data.error;
                        $this.overlay = true;
                        $this.progress = false;
                    }
                })
                    .catch(function () {
                        $this.errorMessage = 'Ошибка';
                        $this.overlay = true;
                        $this.progress = false;
                    });
            },
            skuClass(productId, colorId, sizeId) {
                let sales, orders = 0;
                if (this.skuStat.sales[productId] && this.skuStat.sales[productId][colorId] && this.skuStat.sales[productId][colorId][sizeId]) {
                    sales = this.skuStat.sales[productId][colorId][sizeId].q;
                }
                if (this.skuStat.orders[productId] && this.skuStat.orders[productId][colorId] && this.skuStat.orders[productId][colorId][sizeId]) {
                    orders = this.skuStat.orders[productId][colorId][sizeId].q;
                }
                if (orders + sales > 0) {
                    return {backgroundColor: '#FFE7EE'};
                }
                return  '';
            }
        },
        mounted() {
            this.refresh();
        },
        watch: {
            tab () {



                if (this.tab == 1) {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    let $this = this;
                    this.progress = true;
                    axios({
                        url: '/api/wb/statsku',
                        method: 'GET',
                        params: {
                            from: urlParams.get('from'),
                            to: urlParams.get('to')
                        }
                    }).then(function (response) {
                        if (response.data.orders) {
                            $this.skuStat = response.data;
                            $this.progress = false;
                        } else {
                            $this.errorMessage = response.data.error;
                            $this.overlay = true;
                            $this.progress = false;
                        }
                    })
                        .catch(function () {
                            $this.errorMessage = 'Ошибка';
                            $this.overlay = true;
                            $this.progress = false;
                        });
                }
                else if (this.tab == 2) {
                    let $this = this;
                    this.progress = true;
                    axios({
                        url: '/api/wb/getarts',
                        method: 'GET'

                    }).then(function (response) {
                        if (response.data.arts) {
                            $this.arts = response.data.arts;
                            $this.progress = false;
                        } else {
                            $this.errorMessage = response.data.error;
                            $this.overlay = true;
                            $this.progress = false;
                        }
                    })
                        .catch(function () {
                            $this.errorMessage = 'Ошибка';
                            $this.overlay = true;
                            $this.progress = false;
                        });
                }
            }
        }

    }
</script>
